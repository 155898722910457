<template>
  <div>
    <q-r-code-dialog
      v-model="pageData.isQRWindowOpen"
      :qrcode-src="pageData.QRCodeSrc"
    ></q-r-code-dialog>

    <!-- 隐私政策弹窗 -->
    <privacy-policy
      v-model="pageData.isPrivacyPolicyDialogOpen"
    ></privacy-policy>

    <v-card>
      <v-card-title>
        确认本人身份信息
      </v-card-title>

      <v-card-text>
        <v-form
          ref="realNameAuthForm"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <div
            class="my-2"
          >
            <div>
              认证方式
            </div>

            <btn-toggle-group
              v-model="pageData.authType"
              class="mt-1"
              :btn-toggle-props="{ mandatory: true, }"
              :btn-list="[{ label: '人脸识别' }]"
            ></btn-toggle-group>
          </div>

          <div
            class="my-4"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="submitData.realName"
                  label="姓名"
                  placeholder="姓名"
                  hide-details="auto"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="submitData.idCard"
                  label="身份证号"
                  placeholder="身份证号"
                  hide-details="auto"
                  readonly
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div
            class="my-2"
          >
            <div>
              刷脸方式
            </div>

            <div
              class="mt-2"
            >
              <svg-content
                :svg-src="svgAlipay"
                title="支付宝"
                content="需要唤起支付宝APP完成人脸识别"
              ></svg-content>
            </div>
          </div>

          <div>
            <div
              class="d-flex"
            >
              <v-checkbox
                v-model="pageData.privacyPolicyChecked"
                :true-value="true"
                :false-value="false"
                :rules="[trueValidator]"
                hide-details="auto"
              >
                <template #label>
                  我已阅读并同意
                  <span
                    style="color: #6C5C99"
                    @click="pageData.isPrivacyPolicyDialogOpen = true"
                  >
                    《隐私政策》
                  </span>
                </template>
              </v-checkbox>
            </div>

            <div
              class="d-flex"
            >
              <v-checkbox
                v-model="pageData.agreeAuthChecked"
                :true-value="true"
                :false-value="false"
                :rules="[trueValidator]"
                hide-details="auto"
              >
                <template #label>
                  我同意 <span class="checkboxHighlightTextColor">小五兄弟</span> 采集人脸识别数据用于认证服务
                </template>
              </v-checkbox>
            </div>
          </div>

          <div
            class="d-flex justify-end"
          >
            <v-btn
              class="mx-2"
              @click="routerBack"
            >
              返回
            </v-btn>

            <v-btn
              color="success"
              type="submit"
            >
              同意并继续
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api'
import {
  getParsedUserData, refreshUserInfo, userInfo,
} from '@core/utils/userData'
import { useRouter } from '@core/utils'
import { requestGet } from '@core/utils/form'
import { required, trueValidator } from '@core/utils/validation'
import { toastInfo, toastSuccess } from '@core/utils/prompt'
import SvgContent from '@/views/project/real-name-auth/SvgContent.vue'
import QRCodeDialog from '@/views/project/real-name-auth/QRCodeDialog.vue'
import PrivacyPolicy from '@/views/project/real-name-auth/PrivacyPolicy.vue'
import BtnToggleGroup from '@/views/components/form-components/btn-toggle-group/BtnToggleGroup.vue'

export default {
  name: 'RealNameAuth',
  components: {
    SvgContent,
    QRCodeDialog,
    PrivacyPolicy,
    BtnToggleGroup,
  },
  setup() {
    const qrcode = ref(null)
    const valid = ref(false)
    const realNameAuthForm = ref(null)
    const pageData = reactive({
      isPrivacyPolicyDialogOpen: false,
      privacyPolicyChecked: false,
      agreeAuthChecked: false,
      isQRWindowOpen: false,
      QRCodeSrc: '',
      authType: [1],
    })

    const { router } = useRouter()

    const routerBack = () => {
      router.back()
    }

    const svgAlipay = require('@/assets/images/svg/alipay.svg')

    const submitDataOri = {
      idCard: userInfo.cpType === 1 ? userInfo.contractIdCard : userInfo.idCard,
      mobile: userInfo.cpType === 1 ? userInfo.contractMobile : userInfo.mobile,
      memberId: userInfo.id,
      contractId: userInfo.contractId,
      realName: userInfo.cpType === 1 ? userInfo.contractRealName : userInfo.realName,
    }

    const submitData = reactive({ ...submitDataOri })
    console.log(submitData)

    const onSubmit = () => {
      if (valid.value) {
        if (userInfo.cpType === 1) {
          requestGet('/authentic/getContractIdentifyUrl', submitData).then(res => {
            pageData.isQRWindowOpen = true
            pageData.serialNo = res.data.serialNo
            pageData.QRCodeSrc = res.data.identifyUrl
          })

          return
        }
        requestGet('/authentic/getIdentifyUrl', submitData).then(res => {
          pageData.isQRWindowOpen = true
          pageData.serialNo = res.data.serialNo
          pageData.QRCodeSrc = res.data.identifyUrl
        })
      } else realNameAuthForm.value.validate()
    }

    const checkAuth = () => {
      requestGet('/client/userInfo').then(res => {
        const parsedUserData = getParsedUserData(res.data)
        if (userInfo.cpType === 1 ? parsedUserData.contractPersonAuth : parsedUserData.auth) {
          toastSuccess('认证成功！')
          pageData.isQRWindowOpen = false
          refreshUserInfo()
        } else toastInfo('认证失败，请确认是否认证成功！')
      })
    }

    return {
      qrcode,
      valid,
      pageData,
      svgAlipay,
      submitData,
      realNameAuthForm,
      checkAuth,
      routerBack,
      onSubmit,

      required,
      trueValidator,
    }
  },
}
</script>

<style scoped>

</style>
